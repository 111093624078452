import React from "react"
import { ThemeProvider } from "styled-components"
import mq from "./mediaQueries"
import fonts from "./fonts"
import colors from "./colors"
import wrappersContainers from "./wrappersContainers"
import buttonStyles from "./components/button/buttonStyle"
import buttons from "./buttons"
import menu from "./components/menu/menuStyles"
import formElements from "./formElements"


const theme = {

  mq: mq,

  spacing: [0, 4, 8, 16, 32, 64],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1358,
      xl: 1536,
    },
  },
  palette: {
    secondary: {
      dark: "red"
    }
  },
  colors: colors,

  fonts: fonts,

  wrappersContainers: wrappersContainers,

  button: buttonStyles, // Not sure if this is being used?

  buttons: buttons,

  menu: menu,

  formElements: formElements,

}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import fetch from "cross-fetch"

const httpLink = new HttpLink({
  fetch,
  uri: process.env.GATSBY_GRAPH_ADDR + "/graphql",
  credentials: 'same-origin',
})

const cache = new InMemoryCache({
  typePolicies: {
    PropertyAttributeFacet: {
      // diable normalisation  for facets.
      keyFields: false,
    },
  },
});

const errorHandling = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
})

export const apollo = new ApolloClient({
  cache: cache,
  link: ApolloLink.from([
    errorHandling,
    //  authLink,
    httpLink
  ]),
})

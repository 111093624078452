//import mq from "../../mediaQueries"

const motion = {
  config: {
    mass: 5,
    tension: 2000,
    friction: 200,
    delay: 0,
    duration: 1,
  },
  spring: {
    from: {
      default: {
        from: {
          opacity: 0,
          transform: "translate3d(100%,0,0)",
        },
      },
      xl: {
        from: {
          opacity: 0,
          display: "none",
          transform: "translate3d(100%,0,0)",
        },
      },
    },
    to: {
      default: {
        to: {
          opacity: 1,
          transform: "translate3d(0%,0,0)",
        },
      },
      xl: {
        to: { opacity: 1, display: "block", transform: "translate3d(0%,0,0)" },
      },
    },
  },
  trans: {
    from: {
      default: {
        from: {
          opacity: 0,
          transform: "translateY(5px)",
        },
      },
      xs: {
        from: {
          opacity: 0,
          transform: "translateY(5px)",
        },
      },
      sm: {
        from: { opacity: 0, transform: "translateY(5px)" },
      },
      md: {
        from: { opacity: 0, transform: "translateY(5px)" },
      },
      lg: {
        from: { opacity: 0, transform: "translateY(5px)" },
      },
      xl: {
        from: {
          opacity: 0,
          transform: "translateX(5px)",
          color: "",
        },
      },
    },
    enter: {
      default: {
        enter: {
          opacity: 1,
          transform: "translateY(0px)",
        },
      },
      xs: {
        enter: {
          opacity: 1,
          transform: "translateY(0px)",
        },
      },
      sm: {
        enter: { opacity: 1, transform: "translateY(0px)" },
      },
      md: {
        enter: { opacity: 1, transform: "translateY(0px)" },
      },
      lg: {
        enter: { opacity: 1, transform: "translateY(0px)" },
      },
      xl: {
        enter: { opacity: 1, transform: "translateX(0px)", color: "tomato" },
      },
    },
    leave: {
      default: {
        leave: {
          opacity: 0,
          transform: "translateY(5px)",
        },
      },
      xs: {
        leave: {
          opacity: 0,
          transform: "translateY(5px)",
        },
      },
      sm: {
        leave: { opacity: 0, transform: "translateY(5px)" },
      },
      md: {
        leave: { opacity: 0, transform: "translateY(5px)" },
      },
      lg: {
        leave: { opacity: 0, transform: "translateY(5px)" },
      },
      xl: {
        leave: {
          opacity: 0,
          transform: "translateX(5px)",
          color: "",
        },
      },
    },
  },
}

export default motion

import React, { createContext, useState, useEffect, useContext } from "react"
import { string, object } from 'yup'
import { CheckoutContext } from "./checkout"

const UserContext = createContext()

const UserProvider = props => {
  const schema = object({
    id: string(),
    email: string().email().required(),
    title: string().nullable(),
    firstname: string().nullable(),
    lastname: string().nullable(),
    telephone: string().required(),
    street: string().nullable(),
    locality: string().nullable(),
    town: string().nullable(),
    county: string().nullable(),
    postcode: string().nullable(),
    country: string().nullable(),
  })

  const storage = (typeof sessionStorage !== `undefined`) ? sessionStorage : {}
  const init = storage.userState && JSON.parse(storage.userState)

  const [user, set] = useState(schema.isValidSync(init)
    ? schema.cast(init)
    : {}
  )

  const { resetCheckout } = useContext(CheckoutContext)

  const setUser = data => schema.validate(data).then(result => set(result))
  const initUser = data => setUser(data).then(resetCheckout)

  const store = {
    user,
    setUser,
    initUser,
    isLoggedIn: () => user.id !== undefined
  }

  useEffect(() => storage.setItem('userState', JSON.stringify(user)))

  return <UserContext.Provider value={store} {...props} />
}

export { UserProvider as default, UserContext }

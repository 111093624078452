import React from "react"
import Svg from "react-inlinesvg"

const Icon = ({ className = "", name, width, height, ...props }) => {


  const iconClass = `svgWrapper ${className}`;

  const src = "/svgs/icons/" + name + ".svg"

  return (
    <Svg className={iconClass} src={src} width={width} height={height} />
  )
}

export default Icon
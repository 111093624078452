import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import styled from "styled-components"
import { Box } from "@mui/material"

import MailChimpForm from "./mailChimpForm"

const NewsletterWrapper = styled(Box)`
  color: white;
  .inner {
    ${(props) => props.theme.wrappersContainers.primaryWrapper};

    .newsButton {
      ${(props) => props.theme.buttons.primary};
    }

    .title {
      margin: 0;
    }

    .newsForm {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, auto);
      grid-template-areas:
        "text"
        "name"
        "email"
        "sub";

      @media ${(props) => props.theme.mq.sm} {
        grid-gap: 1rem 3rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
          "text text"
          "name email"
          "sub sub";
      }

      @media ${(props) => props.theme.mq.md} {
        ${(props) => props.theme.wrappersContainers.primaryWrapper};
      }

      .text {
        grid-area: text;
      }

      .name {
        grid-area: name;
        input {
          ${(props) => props.theme.formElements.input};
        }
      }

      .email {
        grid-area: email;
        input {
          ${(props) => props.theme.formElements.input};
        }
      }

      .subbers {
        grid-area: sub;
        display: flex;
        align-items: center;
        flex-direction: column;
        @media ${(props) => props.theme.mq.sm} {
          flex-direction: row;
        }

        .vali {
          margin-top: 1rem;
          @media ${(props) => props.theme.mq.sm} {
            margin-top: 0;
            margin-left: 1rem;
          }
        }

        button {
          ${(props) => props.theme.buttons.primary};
          width: 100%;
          @media ${(props) => props.theme.mq.sm} {
            width: fit-content;
          }
        }
      }
    }
  }
`

const Newsletter = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        companyDetails {
          companyDetails {
            backgroundImage {
              desktop: localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 50
                  )
                }
              }
              mobile: localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 414
                    height: 448
                    quality: 50
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  const image = getImage(data.wp.companyDetails.companyDetails.backgroundImage.desktop?.childImageSharp.gatsbyImageData)
  const bgImage = convertToBgImage(image)
  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
    >
      <NewsletterWrapper sx={{ py: '3rem', display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr', alignItems: 'center', justifyContent: 'center', height: 'fit-content' }}>
        <Box sx={{ gridColumn: '1/1', gridRow: '1/1', position: 'relative', zIndex: 1, }} className="inner">
          <MailChimpForm />
        </Box>
      </NewsletterWrapper>
    </BackgroundImage>
  )
}

export default Newsletter

import React, { createContext, useState, useEffect, useContext } from "react"
import { string, number, boolean, date, object, array } from 'yup'
import { CheckoutContext } from "./checkout"
import { gql } from '@apollo/client'
import { apollo } from "../../../../src/apollo/client"

class Booking {
  constructor(args) {
    Object.assign(this, args);
  }
  //options available for this property
  _options = [];
  //prices for this booking
  prices = null;

  payment = {
    transactionId: null,
    amount: null,
  }

  // Getter
  get options() {
    return this._options.filter(option => {
      return (!option.compulsory && option.name !== 'Pet' && option.name !== 'Cot' && option.name !== 'High Chair');
    });
  }
}

const GET_BOOKING_INFO = gql`
  query GetBookingInfo($query: PriceQuery, $id: Int ) {
    getBookingPrice(query: $query) {
      balanceDue
      totalCost
      depositCost
      bookingFee
      properties {
        #discount
        extras {
          id
          name
          title
          qty: quantity
          extraCost
          netCost
          totalCost
        }
      }
    }
    getBookingExtras(id: $id) {
      id
      name
      image
      priceItem
      priceWeek
      pricePerson
      maxQty
      payableToOwner
      compulsory
    }
  }
`

const BookingContext = createContext()

const BookingProvider = props => {
  const schema = object({
    id: number(),
    status: string(),
    ref: number().integer().required(),//property ref!±!
    bookingRef: string(),
    propImageUrl: string().required(),
    propName: string().required(),
    propRef: string().required(),//customer-facing ref
    dates: object({
      from: date().min(new Date()).required(),
      to: date().min(new Date()).required(),
    }),
    guests: object({
      adults: number().integer().min(1).required(),
      children: number().integer().min(0).default(0),
      infants: number().integer().min(0).default(0),
      pets: number().integer().min(0).default(0),
    }),
    extras: array().of(
      object({
        id: number(),
        qty: number().min(0),
        name: string(),
        title: string(),
        extraCost: number().min(0),
        netCost: number().min(0),
        payableToOwner: boolean()
      })
    ),
    bookingType: string(),
    notes: string(),
    source: string(),
    //voucher: string(),
    browser: object({
      challengeWindowSize: string(), //"Small",
      language: string(), //"en-GB"
      colorDepth: string(), //"16",
      screenHeight: string(), //"768",
      screenWidth: string(), //"1200",
      tZ: string(), //"+300",
      userAgent: string()
    })
  })

  const storage = typeof sessionStorage !== `undefined` ? sessionStorage : {}
  const init = storage.bookingState && JSON.parse(storage.bookingState)

  const [booking, set] = useState(schema.isValidSync(init)
    ? schema.cast(init)
    : {}
  )

  const { resetCheckout } = useContext(CheckoutContext)

  const request = priceQueryVars => apollo.query({
    query: GET_BOOKING_INFO,
    variables: priceQueryVars,
    fetchPolicy: "network-only",
  })

  const getPrice = async (result) => {
    const response = await request(priceQueryVars(result));
    const data = response;
    console.log(data);
    return (data.data)
  }

  const setBooking = async (data) => {
    const result = await schema.validate({ ...data, prices: undefined })
    const prices = await getPrice(result);
    return setWithPrice(result, prices);
  }

  const initBooking = data => setBooking(data).then(resetCheckout)

  const resetBooking = () => {
    set({})
    resetCheckout()
  }

  const priceQueryVars = (booking) => {
    const guests = booking.guests || {}

    return {
      query: {
        which: {
          proprefs: [booking.ref?.toString()]
        },
        when: {
          from: booking.dates?.from.toISOString(),
          to: booking.dates?.to.toISOString()
        },
        who: {
          adults: guests.adults,
          children: guests.children,
          infants: guests.infants,
          pets: guests.pets,
        },
        want: {
          extras: booking.extras?.map(extra => ({ id: extra.id, qty: extra.qty })),
        }
      },
      id: booking.ref
    }
  }

  const setWithPrice = (booking, queryData) => {
    if (queryData) {
      const getPrice = queryData?.getBookingPrice?.totalCost ?? 0

      const getDeposit = queryData?.getBookingPrice?.depositCost ?? 0

      const getBalanceDueDate = queryData?.getBookingPrice?.balanceDue ?? Date.now()

      const bookingExtras = queryData?.getBookingPrice?.properties[0]?.extras

      const isBalanceDue = Date.now() >= Date.parse(getBalanceDueDate)

      const paymentDue = booking.bookingType === 'paylater' ? 0 : (booking.bookingType === 'paydeposit' && !isBalanceDue ? getDeposit : getPrice)

      console.log(`price query result ${getPrice}`);

      return set(new Booking({
        ...booking,
        prices: {
          totalPrice: getPrice,
          deposit: getDeposit,
          balanceDueDate: getBalanceDueDate,
          paymentDue: paymentDue,
          extras: bookingExtras,
        },
        _options: queryData.getBookingExtras,
      })
      );
    }
  }

  const setBookingRef = (id, bookingRef, status) => {
    return set(new Booking({
      ...booking,
      id: id,
      bookingRef: bookingRef.toString(),
      status: status
    })
    );
  }

  const setBookingStatus = (status, transactionId, amount) => {
    return set(new Booking({
      ...booking,
      status: status,
      payment: {
        transactionId: transactionId,
        amount: amount,
      }
    })
    );
  }

  const processBookingExtra = (newExtra, booking) => {
    const extraNdx = booking.extras?.findIndex(extra => {
      return (newExtra.name ? extra.name.toLowerCase() === newExtra.name.toLowerCase() : newExtra.id === extra.id)
    })

    if (extraNdx >= 0) {
      const extra = booking.extras[extraNdx];
      const limitQty = newExtra.qty > extra.maxQty ? extra.maxQty : newExtra.qty
      booking.extras[extraNdx] = { ...extra, qty: limitQty }
      return true
    } else {
      const optExtra = booking._options?.find(extra => {
        return (newExtra.name ? extra.name.toLowerCase() === newExtra.name.toLowerCase() : newExtra.id === extra.id)
      })
      if (optExtra) {
        const limitQty = newExtra.qty > optExtra.maxQty ? optExtra.maxQty : newExtra.qty
        booking.extras.push({ ...optExtra, qty: limitQty })
        return true
      }
    }
    return false
  }

  const addBookingExtra = async (newExtra) => {

    if (processBookingExtra(newExtra, booking)) {
      return setBooking({
        ...booking,
      });
    }
    return
  }

  const cloneArray = (items) => (items.map(item => Array.isArray(item) ? cloneArray(item) : item))

  const updateBookingExtras = (value) => {
    //console.log("Update extra", value)
    var found = false
    var allExtras = []
    const extras = booking.extras?.map(extra => {
      if (extra.id === value.id) {
        found = true
        extra = { ...extra, qty: value.qty, payableToOwner: value.payableToOwner }
      }
      return extra
    })
    if (extras !== undefined)
      allExtras = cloneArray(extras)
    if (!found)
      allExtras.push(value)

    return allExtras
  }

  const store = {
    booking,
    addBookingExtra,
    updateBookingExtras,
    setBooking,
    initBooking,
    resetBooking,
    setBookingRef,
    setBookingStatus,
  }

  useEffect(() => storage.setItem("bookingState", JSON.stringify(booking)))

  return <BookingContext.Provider value={store} {...props} />
}

export { BookingProvider as default, BookingContext }

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box } from "@mui/material"

export const PartnerLogos = () => {
  const data = useStaticQuery(graphql`
    query PartnerLogosQuery {
      wp {
        companyDetails {
          companyDetails {
            partnerLogos {
              partnerLogo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 150,
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', justifyContent: 'space-around', alignItems: 'center', columnGap: "20px" }}>
      {data.wp.companyDetails.companyDetails.partnerLogos.map((node, i) => {
        const image = getImage(node.partnerLogo.localFile.childImageSharp.gatsbyImageData)
        return <Box key={`partner-logo-${i}`}><GatsbyImage style={{ height: 'auto' }} image={image} alt={node.partnerLogo.altText} /></Box>
      })}
    </Box>
  )
}
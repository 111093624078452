import React, { createContext, useContext, useState, useEffect } from "react"
import { ThemeContext } from "styled-components"

export const WindowDimensionsCtx = createContext(null)

const WindowDimensionsProvider = ({ children }) => {
  const themeContext = useContext(ThemeContext)

  const [dimensions, setDimensions] = useState({
    width: 1440,
    height: 900,
    mediaSize: "default",
  })

  useEffect(() => {
    const getBreakpoints = () => {
      const results = Object.keys(themeContext.mq.breakPoints).filter(
        (key, i) => {
          const value = themeContext.mq.breakPoints[key]

          const mql = window.matchMedia("(min-width:" + value + "px)")

          return mql.matches
        }
      )

      const orderObj = results
        .map((key, i) => {
          return {
            breakpoint: key,
            value: themeContext.mq.breakPoints[key],
          }
        })
        .sort((a, b) => {
          return b.value - a.value
        })

      return orderObj !== "undefined" &&
        orderObj !== null &&
        orderObj.length !== null &&
        orderObj.length > 0
        ? orderObj[0].breakpoint
        : "default"
    }

    const handleResize = () => {
      setDimensions({
        width: window.outerWidth,
        height: window.innerHeight,
        mediaSize: getBreakpoints(),
      })
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [themeContext])
  return (
    <WindowDimensionsCtx.Provider value={dimensions}>
      {children}
    </WindowDimensionsCtx.Provider>
  )
}
export default WindowDimensionsProvider
export const useWindowDimensions = () => useContext(WindowDimensionsCtx)

import React, { createContext, useState, useEffect, useContext } from "react"
import { string, object, number } from 'yup'
import { CheckoutContext } from "./checkout"

const DataLayerContext = createContext()

const DataLayerProvider = props => {
  const schema = object({
    bookingRef: string(),
    totalPrice: number().integer().min(0).default(0),
  })

  const storage = (typeof sessionStorage !== `undefined`) ? sessionStorage : {}
  const init = storage.dataLayerState && JSON.parse(storage.dataLayerState)

  const [dataLayerStore, setDataLayerStore] = useState(schema.isValidSync(init)
    ? schema.cast(init)
    : {}
  )

  const { resetCheckout } = useContext(CheckoutContext)

  const setDataLayer = data => schema.validate(data).then(result => setDataLayerStore(result))
  const initDataLayer = data => setDataLayer(data).then(resetCheckout)

  const store = {
    dataLayerStore,
    setDataLayerStore,
    initDataLayer
  }

  useEffect(() => storage.setItem('dataLayerState', JSON.stringify(dataLayerStore)))

  return <DataLayerContext.Provider value={store} {...props} />
}

export { DataLayerProvider as default, DataLayerContext }

import React, { useState } from "react"
import { Button as GatsbyButton } from "gatsby-theme-material-ui"
import styled from "styled-components"
import MenuPanel from "./menu-panel"
import Icon from "../../utils/icon"
import { Button } from "@mui/material"
import ButtonLink from "../button/buttonLink"


const PanelNav = styled.div`
  height: 3rem;
  padding: 1rem 2rem;
  text-align: right;

  @media ${props => props.theme.mq.md} {
    display: none;
  }
  svg{
    fill:${props => props.theme.colors.secondary.main}
  }
`
const MenuItem = styled.li.attrs({
  className: "menuItem"
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin: 0 1rem;
  padding: 0.5rem 0;

  border-top: 1px solid transparent;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};

  @media ${props => props.theme.mq.md} {
    display: block;
    align-items: unset;
    justify-content: unset;
    width: auto;
    text-align: center;
    padding: 1rem 0rem;
    margin: 0rem;
    border-bottom: revert;
  }
  .svgWrapper{
    float: right;
    @media ${props => props.theme.mq.md} {
      display: none;
    }
  }

`

const MenuItemList = styled.ul`
  list-style-type: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
  
  margin: 0;
 // height: 100%;

  @media ${props => props.theme.mq.md} {
    background-color: inherit;
    width: auto;
    flex-wrap: nowrap;
    padding: 0rem 0rem;
    order: 2;
  }    
  `

const MenuContainer = styled.nav`
  background-color: white;
  color: ${(props) => props.theme.colors.secondary.main};
  ${(props) => props.theme.fonts.smallerFontSize};
  width: 100%;
  min-height: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;

  .divider{
    @media ${props => props.theme.mq.md} {
      width: 100%;
      position: absolute;
      left: 0;
    }
  }

  @media ${props => props.theme.mq.md} {
    background-color: revert;
    width: 100%;  
    height: revert;
    position: revert;
    top: revert;
    z-index: revert;
    overflow-y: revert;
  }
  &.active {
    display:revert;
    animation: 0.2s slideInLeftAnimation;
    @media ${props => props.theme.mq.md} {
      animation: unset;
    }
    @keyframes slideInLeftAnimation {
	0% {
		opacity: 0;
		transform: translateX(-250px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
  }

`

//=================================
//
// const Menu = React.memo(props => {
const Menu = props => {

  const onClose = props.onClose;
  const open = props.open;
  const children = props.children;
  const menu = props.menu;
  //const name = props.name;

  // parent menu items
  const menuItems = () => {
    return (menu.menuItems.items.filter(i => !i.parentId));
  }

  //remember any open submenus
  const defaultState = {
    panelID: -1,
  }

  const [menuState, updateMenuStatus] = useState(defaultState)
  const thisMenu = menuState;
  //
  const renderLink = (item, i) => {
    const itemPath = item.path;

    const menuIcon =
      "menu_icon" in item && item.menu_icon.menuIcon.showMenuIcon === true ? (
        <Icon
          name={item.menu_icon.menuIcon.selectMenuIcon}
          height="15px"
          fill="white"
        />
      ) : (
        ""
      )

    function iconz() {

      if (item.menu_icon) {
        return item.menu_icon.menuIcon.showMenuText !== null ? item.label : ""
      } else {
        return item.label
      }

    }

    const link =
      itemPath.indexOf("http://") === 0 ||
        itemPath.indexOf("https://") === 0 ||
        itemPath.indexOf("mailto:") === 0 ||
        itemPath.indexOf("#") === 0 ||
        itemPath.indexOf("tel:") === 0 ? (
        <a href={itemPath}
          style={{ fontWeight: 300, fontSize: { xs: 12, md: 12, lg: 16 } }}
        // onMouseEnter={e => optionSelect(e, i)}
        >
          {menuIcon}
          {iconz()}
        </a>
      ) : (
        <GatsbyButton
          color="primary"
          size="small"
          variant="text"
          to={itemPath}
          //onMouseEnter={e => optionSelect(e, i)}
          onClick={e => optionSelect(e, i)}
          sx={{ fontWeight: 300, fontSize: { xs: 12, md: 12, lg: 16 }, p: { xs: 0 } }}
        >
          {menuIcon}
          {iconz()}
        </GatsbyButton>
      )

    return <>{link}</>
  }

  //
  const renderItem = (item, key, props, i) => {
    if (
      item?.childItems?.subMenu &&
      item.childItems.subMenu.length > 0
    ) {
      return (
        <>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={e => subMenuSelect(e, i)}
            onMouseEnter={e => subMenuSelect(e, i)}
            onKeyDown={e => subMenuSelect(e, i)}
            sx={{ fontWeight: 300, fontSize: { xs: 12, md: 12, lg: 16 }, p: { xs: 0 }, justifyContent: "flex-start" }}
          >
            {item.label}
          </Button>
          <Icon name="cheveron-right" height="1rem" />
        </>
      )
    } else {
      return renderLink(item, i)
    }
  }

  //
  const subMenu = pId => {
    if (pId >= 0) {
      const menu = menuItems()[pId];
      return (menu?.childItems?.subMenu?.length ? menu : null);
    }
    return null;
  }

  // A submenu has closed
  const subMenuClose = (select) => {

    updateMenuStatus({
      ...menuState,
      panelID: -1,
    })
    document.body.classList.remove("noScroll")

    // submenu an item was selected so make sure any subordinates close
    if (select && onClose) {
      onClose(select);
    }
  }


  //A menu option was selected
  const subMenuSelect = (e, i) => {
    const isMenu = subMenu(i) != null;
    // console.log(`Menu sekect ${i} is menu ${isMenu}`)
    updateMenuStatus({
      ...menuState,
      panelID: isMenu ? i : -1,
    })
    if (!isMenu && onClose) {
      // a submenu was selected so tell the parent
      onClose(true);
    }
  }

  //A Link option was selected
  const optionSelect = (e, i) => {
    // console.log(`Option select ${i} is option ${!isMenu}`)
    updateMenuStatus({
      ...menuState,
      panelID: -1,
    })
    closeMenu();
  }

  const closeMenu = () => {
    document.body.classList.remove("noScroll")

    if (onClose)
      onClose(true);
  }
  //**** renderer ******** */  
  return (

    <MenuContainer className={open ? "active" : "inactive"}>

      <PanelNav>
        <ButtonLink onClick={(e) => closeMenu(e)} >
          <Icon name="close" height="1rem" />
        </ButtonLink>
      </PanelNav>

      <MenuItemList>
        {menuItems().map((menuItem, i) => {
          return (
            <MenuItem key={i}>
              {renderItem(menuItem, i, props, i)}
            </MenuItem>
          )
        }
        )}
      </MenuItemList>

      {children}

      <div className="divider">
        {/* submenu */}
        <MenuPanel
          panelData={subMenu(thisMenu.panelID)}
          onClose={subMenuClose}
          open={thisMenu.panelID >= 0}
        />
      </div>
    </MenuContainer>
  )
}

export default Menu

import React, { useContext, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { Button } from "gatsby-theme-material-ui"
import LockIcon from '@mui/icons-material/Lock';
import styled from "styled-components"
import Menu from "../menu/menu"
import LogoIcon from '../../../static/svgs/logos/logo-icon.svg'
import LogoText from '../../../static/svgs/logos/logo-text.svg'
import IconCall from '../../../static/svgs/inline/call.svg'
import IconMenu from '../../../static/svgs/inline/menu.svg'
import IconHeart from '../../../static/svgs/inline/heart.svg'
import PropertySearch from "../search/propertySearch"
import PrivacyMessage from "../privacyMessage/privacyMessage"
import { useMediaQuery, Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WishListContext } from "../../utils/wishListProvider"

const HeaderContainer = styled.div.attrs({
  className: "dontPrint",
})`
  z-index: 2;
`

const TopMenuWrapper = styled.div.attrs({
  className: "topMenuWrapper",
})`
  order: 2;
  background-color: transparent;

  width: 100%;
  background-color: white;

  @media ${(props) => props.theme.mq.sm} {
    padding: 1rem 0;
  }

  nav {
    display: none;
    @media ${(props) => props.theme.mq.sm} {
      display: block;
    }
  }
`
const TopMenuContent = styled.div.attrs({
  className: "topMenuContent",
})`
  ${(props) => props.theme.wrappersContainers.primaryWrapper};
`


const TopMenu = styled.div.attrs({
  className: "topMenu",
})`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .logoWrapper {
    width: 100%;
  }

  .mobileButtons {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @media ${(props) => props.theme.mq.md} {
      display: none;
    }

    .tagLine {
      display: none;
      @media ${(props) => props.theme.mq.md} {
        display: block;
        ${(props) => props.theme.fonts.h6};
        color: ${(props) => props.theme.colors.secondary.main};
        ${(props) => props.theme.fonts.secondaryFont};
        margin: 0.3rem 2rem 0 0;
      }
    }
  }

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    @media ${(props) => props.theme.mq.md} {
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
    li {
      list-style: none;
      padding: 1rem 0;
      margin-bottom: 0px;
      border-bottom: 1px solid transparent;
      border-bottom-color: white;
      margin-bottom: 1rem;
      @media ${(props) => props.theme.mq.md} {
        border-bottom-color: transparent;
        margin-bottom: 0;
        margin-left: 1rem;
        padding: 0;
      }
      a {
        text-decoration: none;
        color: inherit;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.colors.secondary.main};
        .svg {
          display: flex;
          align-items: center;
          max-height: 1rem;
          fill: ${(props) => props.theme.colors.secondary.main};
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`
const BottomMenuWrapper = styled.div.attrs({
  className: "bottomMenuWrapper",
})`
  margin: 0 auto;
  background: linear-gradient(
    180deg,
    #dbd8df,
  //  #dbd8df 10%,
  //  #f9f8fb 50%,
    white 15%
  );
`

const BottomMenuContent = styled.div.attrs({
  className: "bottomMenuContent",
})`
  ${(props) => props.theme.fonts.smallerFontSize};
  ${(props) => props.theme.wrappersContainers.primaryWrapper};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  overflow: hidden;
  padding: 0;
  //background-color: transparent;

  @media ${(props) => props.theme.mq.md} {
    width: 100%;
    height: auto;
    position: relative;
    overflow: visible;
    padding: 0 3rem;
  }
`

const RightSide = styled.div.attrs({
  className: "rightSide",
})`
  display: none;
  @media ${(props) => props.theme.mq.md} {
    color: ${(props) => props.theme.colors.secondary.main};
    font-size: 1rem;
    display: block;
    width: 100%;
    ${(props) => props.theme.fonts.secondaryFont};
    text-align: right;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.secondary.main};
    ${(props) => props.theme.fonts.primaryFont};
    font-weight:300;
  }

  .tagLine {
    ${(props) => props.theme.fonts.h6};
  }

  span {
    ${(props) => props.theme.fonts.primaryFont};
    margin-left: 2rem;
    display: block;
    @media ${(props) => props.theme.mq.md} {
      display: span;
    }
  }
`

const MenuButton = styled.button`
  outline: none;
  border: none;
 // z-index: 8;
  background-color: transparent;
  padding: 0;
  margin: 0;
`
const MobileExtras = styled.div`
  border-top: 1px solid transparent;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;

    a {
      color: ${(props) => props.theme.colors.secondary.main};
      text-decoration: none;
    }
    .svgWrapper{
      fill: ${(props) => props.theme.colors.primary.main};
      display:inline;
    }

`
const WishListWrapper = styled.div.attrs({
  className: "wishlist",
})`
  display: flex;

  svg {
    fill: ${(props) => props.theme.colors.primary.main};
    @media ${(props) => props.theme.mq.md} {
      margin: 0.5rem 0.75rem 0.25rem 0.75rem;
    }  
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.secondary.main};
    ${(props) => props.theme.fonts.smallerFontSize};
    margin-right:1rem;
    @media ${(props) => props.theme.mq.md} {
      margin-right:0;
    }
  }
  @media ${(props) => props.theme.mq.md} {
    flex-direction: column;
    background-color: #dbd8df;
    align-items: center;
    border-radius: 0 0 0.25rem 0.25rem;
    ${(props) => props.theme.fonts.smallerFontSize};
  }
`
const MenuItem = styled.li.attrs({
  className: "menuItem"
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin: 0 1rem;
  padding: 0.5rem 0;

  border-top: 1px solid transparent;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};

  @media ${props => props.theme.mq.md} {
    display: block;
    align-items: unset;
    justify-content: unset;
    width: auto;
    text-align: center;
    padding: 1rem 0rem;
    margin: 0rem;
    border-bottom: revert;
  }
  .svgWrapper{
    float: right;
    @media ${props => props.theme.mq.md} {
      display: none;
    }
  }

`

const MenuItemList = styled.ul`
  list-style-type: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0rem 1rem 1rem 1rem;
  
  margin: 0;
 // height: 100%;

  @media ${props => props.theme.mq.md} {
    background-color: inherit;
    width: auto;
    flex-wrap: nowrap;
    padding: 0rem 0rem;
    order: 2;
  }    
  `


const Header = (props) => {
  return (
    <HeaderContainer>
      <HeaderInner themeClass="headerWrapper" {...props} />
    </HeaderContainer>
  )
}

const HeaderInner = (props) => {

  const data = useStaticQuery(graphql`
  query HeaderQuery {

    wp {
      companyDetails {
        companyDetails {
          companyname
          companyemail
          companyphonenumber
          companydescription
        }
      }
    }

      topMenu: wpMenu(name: {eq: "Top Nav"}) {
            menuItems {
              items: nodes {
                url
                path
                title
                childItems {
                  subMenu: nodes {
                    title
                    url
                    path
                    target
                    label
                    parentId
                    menu_feature {
                      menuFeature {
                        title
                        showMenuFeature
                        excerpt
                        fieldGroupName
                        images {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(
                                width: 1920
                                quality: 50
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                              )
                            }
                          }
                        }
                      }
                    }
                    menu_icon {
                      menuIcon {
                        showMenuText
                        showMenuIcon
                        selectMenuIcon
                        fieldGroupName
                      }
                    }
                  }
                }
                label
                menu_icon {
                  menuIcon {
                    selectMenuIcon
                    showMenuIcon
                    showMenuText
                  }
                }
              }
            }
            slug
            name
      }

      bottomMenu: wpMenu(name: {eq: "Main"}) {
            menuItems{
              items: nodes {
                url
                path
                title
                parentId
                menu_feature {
                  menuFeature {
                    showMenuFeature
                    images {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            width: 768
                            quality: 50
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                          )
                        }
                      }                    }
                  }
                }
                childItems {
                  subMenu: nodes {
                    
                      menu_feature {
                        menuFeature {
                          title
                          showMenuFeature
                          excerpt
                          fieldGroupName
                          images {
                            altText
                            localFile {
                              childImageSharp {
                                gatsbyImageData(
                                  width: 768
                                  quality: 50
                                  placeholder: BLURRED
                                  formats: [AUTO, WEBP, AVIF]
                                )
                              }
                            }
                          }
                        }
                      }
                      menu_icon {
                        menuIcon {
                          showMenuText
                          showMenuIcon
                          selectMenuIcon
                          fieldGroupName
                        }
                      }
                      title
                      url
                      target
                      label
                      path
                  }
                }
                label
              }
            }
            slug
            name
      }
    
  }
`)

  const deets = data.wp.companyDetails.companyDetails
  const topMenu = data.topMenu;

  const theme = useTheme();
  const bottomMenu = data.bottomMenu;

  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const defaultState = {
    burger: false,
  }
  const [menuState, updateMenuStatus] = useState(defaultState);

  //wishlist count
  const { wishList } = useContext(WishListContext)

  const wishlistItemCount = () => {
    const wlc = { wishList };
    return (wlc?.wishList.length ?? 0);
  }

  function showPropertySearch(display) {

    if (display) {
      return (
        <PropertySearch concise={true} onchange={e => subMenuClose(true)} />
      )
    } else {
      return
    }
  }


  function wishlistCount(display) {
    // var wishlistCount = { wishList }

    if (wishlistItemCount() > 0 && display) {
      return (
        <WishListWrapper>
          <Link to="/wishlist"
            onClick={e => subMenuClose(true)}>
            <IconHeart height="20px" />
          </Link>
          {wishlistItemCount()}
        </WishListWrapper>
      )
    } else {
      return
    }
  }

  const subMenuClose = (select) => {
    if (select) {
      updateMenuStatus({
        burger: false,
      })
    }
  }

  const burgerClick = () => {
    updateMenuStatus({
      burger: !menuState.burger,
    })
  }

  return (
    <>
      <PrivacyMessage />
      <TopMenuWrapper themeClass={props.themeClass}>
        <TopMenuContent>
          <TopMenu>
            <Link to={"/"} title="Home" className="logoWrapper">
              <Box sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                minHeight: "5.625rem",
                width: {xs: "180px", sm: "200px", md: "320px"},
                  "& .svgWrapper:first-of-type": {
                    width: "30%",
                  }
              }}themeClass={props.themeClass}>
                <LogoIcon />
                <LogoText />
              </Box>
            </Link>
            <div className="mobileButtons">
              <div className="tagLine">{deets.companydescription}</div>
              <IconButton className="call"
                href={"tel:" + deets.companyphonenumber}
                title="Call us"
                aria-label="Call us" sx={{
                  px: 2,
                }}>
                <IconCall width="2.5rem" />
              </IconButton>
              
              <Burger
                active={menuState.burger}
                onClick={burgerClick}
              />
            </div>
            <RightSide>
              <div className="tagLine">
                {deets.companydescription}{" "}
                <span>
                  Call{" "}
                  <a
                    className="call"
                    href={"tel:" + deets.companyphonenumber}
                    title="Call us"
                    style={{ fontWeight: 300 }}
                  >
                    {deets.companyphonenumber}
                  </a>
                </span>
              </div>
              <Menu
                menu={topMenu}
                name="topMenu"
              />
            </RightSide>
          </TopMenu>
        </TopMenuContent>
      </TopMenuWrapper>

      <BottomMenuWrapper>
        <BottomMenuContent>
          <Menu
            menu={bottomMenu}
            name="BottomMenu"
            open={menuState.burger || desktop}
            onClose={subMenuClose}
          >
            {menuState.burger && (
              <MobileExtras>

                <MenuItemList>

                  <MenuItem>
                    <Button variant="text" color="primary" size="small" sx={{ display: "inline-flex", fontWeight: 300, fontSize: { xs: 12, md: 12, lg: 16 }, p: { xs: 0 }, alignItems: 'center', }} to="/log-in" title="Guest Log In" className="login"
                      onClick={e => subMenuClose(true)} startIcon={<LockIcon sx={{ height: { xs: 12, md: 25 } }} />}
                    >

                      Guest Log In
                    </Button>
                  </MenuItem>

                  <MenuItem>
                    <Button variant="text" color="primary" size="small" sx={{ display: "inline-flex", fontWeight: 300, fontSize: { xs: 12, md: 12, lg: 16 }, p: { xs: 0 }, alignItems: 'center', }} to="/owners" title="Owner Log In" className="login"
                      onClick={e => subMenuClose(true)} startIcon={<LockIcon sx={{ height: { xs: 12, md: 25 } }} />}
                    >

                      Owner Log In
                    </Button>
                  </MenuItem>

                  {!desktop && <MenuItem>
                    {wishlistCount(!desktop)}
                  </MenuItem>}

                  {!desktop && <MenuItem>
                    {showPropertySearch(!desktop)}
                  </MenuItem>}
                </MenuItemList>

              </MobileExtras>
            )}
          </Menu>
          {showPropertySearch(desktop)}
          {wishlistCount(desktop)}
        </BottomMenuContent>
      </BottomMenuWrapper>
    </>
  )
}
const Burger = ({ active, onClick }) => {

  const updateBurger = (e) => {
    if (onClick) {
      onClick();
    }
    document.body.classList.toggle("noScroll")
  }
  return (
    <>
      <IconButton
        onClick={(e) => updateBurger(e)}
        aria-label="Toggle Mobile Menu"
        title="Open Mobile Menu"
         sx={{
          px: 0
         }}
      >
        <IconMenu width="2.5rem" />
      </IconButton>
    </>
  )
}

export default Header

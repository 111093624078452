
import { css } from "styled-components"

const primary = css`
    cursor: pointer;
    text-decoration:none;
    padding: 0.5rem 1rem;
    width: fit-content;
    display: block;
    color: white;
    border-radius: 4px;
    background-color: ${props => props.theme.colors.primary.main};
    border: 2px solid ${props => props.theme.colors.primary.main};
    transition:all 0.3s ease;
        &:hover{
            background-color: ${props => props.theme.colors.primary.dark};
            border: 2px solid ${props => props.theme.colors.primary.dark};
        }

        &[disabled], &:disabled {
            opacity:0.5;
            cursor: default;
            pointer-events: none;
                &:hover{
                    color: white;
                    background-color: ${props => props.theme.colors.primary.main};
                    border: 2px solid ${props => props.theme.colors.primary.main};
                }
        }
`

const secondary = css`
    cursor: pointer;
    text-decoration:none;
    padding: 0.5rem 1rem;
    width: fit-content;
    display: block;
    color: ${props => props.theme.colors.secondary.main};
    border-radius: 4px;
    background-color: transparent;
    border: 2px solid ${props => props.theme.colors.secondary.main};
    transition:all 0.3s ease;
        &:hover{
            background-color: ${props => props.theme.colors.secondary.dark};
            border: 2px solid ${props => props.theme.colors.secondary.dark};
            color: white;
        }

        &[disabled], &:disabled {
            opacity:0.5;
            cursor: default;
            pointer-events: none;
                &:hover{
                    color: ${props => props.theme.colors.primary.main};
                    background-color: transparent;
                    border: 2px solid ${props => props.theme.colors.primary.main};
                }
        }
`

const buttons = {
    primary,
    secondary,
}

export default buttons

import colors from "../../colors"

const buttonStyles = {
      type: {
            primary: {
                  border: colors.secondary.main + " 2px solid",
                  color: colors.secondary.main,
                  backgroundColor: "transparent",
                  padding: ["0.5rem", "1rem"].join(" "),
                  fontStyle: "italic",
                  hover: {
                        backgroundColor: colors.secondary.main,
                        color: "white",
                  },
            },
            secondary: {
                  border: colors.secondary.main + " 2px solid",
                  color: "white",
                  backgroundColor: colors.secondary.main,
                  padding: ["0.5rem", "1rem"].join(" "),
                  fontStyle: "italic",
                  hover: {
                        backgroundColor: "transparent",
                        color: colors.secondary.main,
                  },
            },
            tertiary: {
                  border: colors.secondary.main + " 2px solid",
                  color: "white",
                  backgroundColor: colors.secondary.main,
                  padding: ["0.5rem", "1rem"].join(" "),
                  fontStyle: "italic",
                  hover: {
                        border: "#ab4619 2px solid",
                        backgroundColor: "#ab4619",
                  },
            },
      },
}



export default buttonStyles

import styled from "styled-components"

const Button = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-weight: 300;
  `

export default Button

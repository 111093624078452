import React from "react"
import { ApolloProvider } from "@apollo/client"
import { apollo } from "./src/apollo/client"
import Theme from "./src/theme/theme"
import WindowDimensionsProvider from "./src/utils/windowDimensionsProvider"
import WishListProvider from "./src/utils/wishListProvider"
import CheckoutProvider from "./src/components/checkout/providers/checkout"
import UserProvider from "./src/components/checkout/providers/user"
import BookingProvider from "./src/components/checkout/providers/booking"
import DataLayerProvider from "./src/components/checkout/providers/storeDataLayer"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={apollo}>
    <Theme>
      <CheckoutProvider>
        <UserProvider>
          <BookingProvider>
            <DataLayerProvider>
              <WindowDimensionsProvider>
                <WishListProvider>
                  {element}
                </WishListProvider>
              </WindowDimensionsProvider>
            </DataLayerProvider>
          </BookingProvider>
        </UserProvider>
      </CheckoutProvider>
    </Theme>
  </ApolloProvider>
)

import React from 'react';
import { Typography } from "@mui/material"

const Heading = ({ children, as = 'span', type, color }) => {

    const formatType = (type, as) => {

        let variant = "h2"
        if (type === 'title') {
            variant = as
        }
        if (type === 'subTitle') {
            variant = 'subtitle1'
        }
        return (
            variant
        )
    }
    return (
        <Typography variant={formatType(type, as)} component={as} color={color} className={type} as={as}>{children}</Typography>
    );
};

export default Heading;
import { createTheme } from "@mui/material"
import colors from "./../theme/colors"

const theme = createTheme({
  typography: {
    fontFamily: [
      'League-Spartan-Light',
      'sans-serif',
    ].join(','),
    fontWeight: 300,
    color: colors.secondary.main,
    backgroundColor: "white",
    h1: {
      fontFamily: 'CrimsonText-Regular, serif',
      color: colors.secondary.main,
    },
    h2: {
      fontFamily: 'CrimsonText-Regular, serif',
      color: colors.primary.main,
    },
    h3: {
      fontFamily: 'CrimsonText-Regular, serif',
      color: colors.secondary.main,
    },
    h4: {
      fontFamily: 'CrimsonText-Regular, serif',
      color: colors.secondary.main,
    },
    h5: {
      fontFamily: 'CrimsonText-Regular, serif',
      color: colors.secondary.main,
    },
    h6: {
      fontFamily: 'CrimsonText-Regular, serif',
      color: colors.secondary.main,
    },
    p: {
      fontFamily: 'League-Spartan-Light, sans-serif',
      fontWeight: 300,
    },
    body1: {
      fontFamily: 'League-Spartan-Light, sans-serif',
      fontWeight: 300,
      lineHeight: 1.7
    },
    subtitle1: {
      fontFamily: 'League-Spartan-Light, sans-serif',
      fontWeight: 300,
      fontSize: '16px !important',
      textTransform: 'uppercase',
      letterSpacing: 0.3
    },
    subtitle2: {
      fontFamily: 'League-Spartan-Light, sans-serif',
      fontWeight: 300,
      fontSize: '12px !important',
      textTransform: 'uppercase',
      letterSpacing: 0.3
    }
  },
  spacing: [0, 4, 8, 16, 32, 64],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1358,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: colors.primary.main,
    },
    secondary: {
      main: colors.secondary.main,
    },
    tertiary: {
      main: colors.tertiary,
    },
  },
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: '1rem',
          "&:hover": {
            cursor: "pointer",
          },
          '&.MuiButton-containedPrimary': {
            cursor: 'pointer',
            textDecoration: 'none',
            padding: '0.5rem 1rem',
            width: 'fit-content',
            display: 'block',
            color: 'white',
            borderRadius: '4px',
            backgroundColor: colors.primary.main,
            border: '2px solid',
            borderColor: colors.primary.main,
            transition: 'all 0.3s ease',
            textTransform: "unset !important",
            fontWeight: 300,
            fontSize: '1rem',
          },
          '&.MuiButton-containedSecondary': {
            cursor: 'pointer',
            textDecoration: 'none',
            padding: '0.5rem 1rem',
            width: 'fit-content',
            display: 'block',
            color: 'white',
            borderRadius: '4px',
            backgroundColor: colors.secondary.main,
            border: '2px solid',
            borderColor: colors.secondary.main,
            transition: 'all 0.3s ease',
            textTransform: "unset !important",
            fontWeight: 300,
            fontSize: '1rem',
          },
          '&.MuiButton-text': {
            borderBottom: '1px solid transparent',
            color: colors.secondary.main,
            backgroundColor: 'transparent',
            borderRadius: 0,
            '&:hover': {
              borderBottom: `1px solid ${colors.secondary.main}`,
            },
          },
        },
      },
    },
  },
});

export default theme

import React from "react";
import { Script, withPrefix } from "gatsby";
import "../../../static/styles/cookie.2.3.min.css"

const PrivacyMessage = () => {
    return (
        <>
            <Script src={withPrefix("/script/cookie.2.3.min.js")} strategy="post-hydrate" />
            <Script src={withPrefix("/script/cookie.2.3.settings.js")} strategy="post-hydrate" />
            <div id="user-consent">
                <div className="blur"></div>
                <div className="page main">
                    <div className="main-container">
                        <div className="content-container">
                            <h2 style={{ display: "none" }}>User consent</h2>
                            <div className="messaging">
                                <p>
                                    We use cookies and other technologies to analyse website usage
                                    and tailor advertisements to your interests. Our partners also
                                    utilise cookies and collect data for remarketing or
                                    personalization.
                                </p>
                                <p>
                                    By clicking "Accept" you consent to the use of cookies, as
                                    well as using your information for remarketing and
                                    personalization purposes. Learn more in our&nbsp;
                                    <a href="/privacy-cookies/">cookie policy</a>&nbsp;or to
                                    modify your preferences, click "Manage".
                                </p>
                            </div>
                        </div>
                        <div className="controls">
                            <button className="solid acceptall">Accept</button>
                            <button className="hollow manage">Manage</button>
                        </div>
                    </div>
                </div>
                <div className="page secondary">
                    <div className="header">
                        <h2 style={{ display: "none" }}>User consent</h2>
                    </div>
                    <div className="content-scroll">
                        <div className="messaging">
                            <p>
                                To find out more about cookies and the types we are setting
                                please visit our&nbsp;<a href="v">cookie policy</a>. If you wish
                                to manage your preferences, use the toggles below to adjust your
                                consent and click "Save choices" to apply your preferences.
                            </p>
                        </div>
                        <div className="toggles">
                            <div className="toggle-set">
                                <h4>Strictly Necessary</h4>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked="true"
                                        disabled="true"
                                        value="necessary"
                                    />
                                    <span className="slider"></span>
                                </label>
                                <p>
                                    These cookies are necessary for the website to function and
                                    without them you would not be able to reliably use the
                                    website. For example, logging into your account or completing
                                    forms.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="controls">
                        <button className="solid acceptall">Accept all</button>
                        <button className="hollow save">Save choices</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrivacyMessage;

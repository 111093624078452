import { useStaticQuery, graphql } from 'gatsby';


const matchProperty = (data, propRef) => {
  let found = null;

  if (data) {
    // Take WP properties data and include the slug
    const records = data.allWpProperty.edges;
    for (const obj of records) {
      if (Number(obj.node.propertyRef) === propRef) {
        found = obj;
        break; // Exit the loop once a match is found
      }
    }
    if (found) {
      // console.log(`Found - ${found.node.slug}`)
      return found
    } else {
      // console.log("NOT FOUND!");
    }
    return found
  }
  return found

}

export const usePropertyUrlFromWpProperties = (propRef) => {
  const data = useStaticQuery(graphql`
  query GetAllWpProperty {
    allWpProperty {
      edges {
        node {
          title
          link
          slug
          propertyRef
        }
      }
    }
    }
  `);

  const found = matchProperty(data, propRef)

  // Return array of data
  if (found) {
    return new URL(`${process.env.GATSBY_CMS_ADDR}/property/${found.node.slug}`).pathname
  }
  else {
    return null
  }

}
export const useWpProperties = () => {
  const data = useStaticQuery(graphql`
  query GetAllWpProperty {
    allWpProperty {
      edges {
        node {
          title
          link
          slug
          propertyRef
        }
      }
    }
    }
  `);
  // Return array of data
  return data.allWpProperty.edges;
}

export const formatPropertyPageUrl = (data, propRef) => {

  let found = null
  if (data && propRef) {
    found = matchProperty(data, propRef)
  }
  if (found) {
    return new URL(`${process.env.GATSBY_CMS_ADDR}/property/${found.node.slug}`).pathname

  }
  else {
    return null
  }

}

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import ListMenu from "./listMenu"
import SocialAccounts from "../socailAccounts/socialAccounts"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

export const FooterMenu = props => {

  const data = useStaticQuery(graphql`
  query FootQuery {
    wp {
      companyDetails {
        companyDetails {
          companyname
          companyemail
          companyphonenumber
        }
      }
    }
  }
`)

  const deets = data.wp.companyDetails.companyDetails

  return (

    <Grid container spacing={4} rowSpacing={4}>

      {props.footerMenu.map((menuItem, i) => {
        return (
          <Grid item xs={12} sm={12} md={6} lg={3}
            key={"footerMenu-" + i}
            className={
              "menuBlock menu-" + i + " " + menuItem.label.replace(/\s+/g, "-").toLowerCase()
            }
          >
            <Accordion sx={{
              display: { xs: 'block', md: 'none' }, backgroundColor: 'transparent', border: 'none', borderBottom: '1px solid white', boxShadow: 'none',
              '& .addIcon': {
                display: 'block'
              },
              '& .removeIcon': {
                display: 'none'
              },
              '&.Mui-expanded': {
                '& .addIcon': {
                  display: 'none'
                },
                '& .removeIcon': {
                  display: 'block'
                }
              }
            }}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {menuItem.label &&
                    menuItem.menu_icon.menuIcon.showMenuText === true &&
                    <Typography sx={{ fontWeight: 700, maxWidth: "70%" }} variant="h6" component="h6" color="white">{menuItem.label}</Typography>}
                  <AddCircleOutlineIcon sx={{ fill: 'white' }} className="addIcon" />
                  <RemoveCircleOutlineIcon sx={{ fill: 'white' }} className="removeIcon" />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ListMenu
                  menuItem={menuItem.subMenu}
                  templateName={menuItem.label.replace(/\s+/g, "-").toLowerCase()}
                ></ListMenu>
              </AccordionDetails>
            </Accordion>

            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {menuItem.label &&
                menuItem.menu_icon.menuIcon.showMenuText === true &&
                <Typography sx={{ fontWeight: 700, maxWidth: "70%", minHeight: 80, pb: { xs: 3 } }} variant="h6" component="h6" color="white">{menuItem.label}</Typography>}
              <ListMenu
                menuItem={menuItem.subMenu}
                templateName={menuItem.label.replace(/\s+/g, "-").toLowerCase()}
              ></ListMenu>
            </Box>

          </Grid>
        )
      }
      )}

      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Box sx={{
          width: '100%', px: { xs: '8px', md: "unset" }, py: { xs: '14px', md: 'unset' }, borderBottom: { xs: '1px solid white', md: 'unset' }
        }}>
          <Typography sx={{ fontWeight: 700, minHeight: 80, maxWidth: "70%", pb: { xs: 3 } }} color="white" variant="h6" component="h6">Get in Touch</Typography>
          <Typography color="white" variant="body1">Call: <Box sx={{ color: 'white', textDecoration: 'none', fontWeight: 200 }} component="a" href={"tel:" + deets.companyphonenumber} title="Call us">{deets.companyphonenumber}</Box></Typography>
          <Typography color="white" variant="body1">Email: <Box sx={{ color: 'white', textDecoration: 'none', fontWeight: 200 }} component="a" href={"mailto:" + deets.companyemail} title="Email us">{deets.companyemail}</Box></Typography>
          <SocialAccounts />
        </Box>
      </Grid>

    </Grid >

  )
}

import { darken } from "@mui/material"

// ** Brand Guidelines 2023 ** //
// "#3b648d", // Dark Blue
// "#30a369", // Green
// "#b7dfe3", // Duck Egg
// "#a8d2f2", // Sky Blue
// "#c0c0c0", // Grey
// "#ccdd8b", // Lime Green

const colors = {
  primary: { // Green
    main: "#30a369",
    dark: darken("#30a369", 0.1)
  },
  secondary: { // Dark Blue,
    main: "#3b648d",
    dark: darken("#3b648d", 0.1)
  },
  tertiary: {
    main: "#a8d2f2",
  }, // Sky Blue
  lightestGrey: "#F7F7F7",
  lightGrey: "#eaeaea",
  mediumGrey: "#c0c0c0", // Grey
  darkGrey: "#4a4a44",

  error: "#ccdd8b", // Lime Green
}

export default colors

import React from "react"
import { Link } from "gatsby"
import { TextField, Box, Autocomplete, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import colors from "../../../theme/colors"
import PlaceIcon from '@mui/icons-material/Place';
import HomeIcon from '@mui/icons-material/Home';


const Locations = ({ value, onSelect, locations, placeholder, variant }) => {
    // console.log("locations", locations);
    // i believe if you pass in an object that looks like an item in the list but isnt the actual it would fail without this.
    const validatedValue = value ? (locations?.filter(el => (el.id === value?.id || el.title === value?.key))[0]) : null;

    const optionComparer = (option, value) => {
        return option.id === value?.id
    };

    //on change function
    const onChange = (event, newValue) => {
        onSelect(newValue);
    };

    const locType = (props, option) => {
        // console.log("option", option);
        if (option.url) {
            return (
                <ListItem {...props}>
                    <Link to={option.url} className="innerLi" style={{
                        display: 'flex',
                        color: colors.secondary.main,
                        textDecoration: "none"
                    }}>
                        <ListItemIcon>
                            <HomeIcon color="primary" sx={{ position: "relative" }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={option.title}
                        />
                    </Link>
                </ListItem>
            )
        } else {
            return (
                <ListItem {...props}>
                    <ListItemIcon>
                        <PlaceIcon color="primary" sx={{ position: "relative" }} />

                    </ListItemIcon>
                    <ListItemText
                        primary={option.title}
                    />
                </ListItem>

            )
        }

    }

    return (

        <Box className="singleFilter" sx={{
            position: "relative", backgroundColor: "white",
            borderRadius: '4px'
        }}>

            <Autocomplete
                id="location-selector"
                blurOnSelect
                options={locations}
                getOptionLabel={(option) => (option?.title ?? "Unknown")}
                value={validatedValue}
                onChange={onChange}
                isOptionEqualToValue={optionComparer}
                renderInput={(params) => (<TextField
                    {...params}
                    placeholder={placeholder ? placeholder : "Where would you like to go?"}
                    variant={variant}
                />)
                }
                renderOption={(props, option) => locType(props, option)}
            />

        </Box>

    );
}

export default Locations
const mq = {

  xl: "screen and (min-width:1536px)",
  lg: "screen and (min-width:1200px)",
  md: "screen and (min-width:900px)",
  sm: "screen and (min-width:600px)",
  xs: "screen and (min-width:0px)",

  // !TOCHECK: Old breakpoints
  // xl: "screen and (min-width:1460px)",
  // lg: "screen and (min-width:1280px)",
  // md: "screen and (min-width:1024px)",
  // sm: "screen and (min-width:768px)",
  // xs: "screen and (min-width:576px)",
  // {
  //   xl: 1460,
  //   lg: 1280,
  //   md: 1024,
  //   sm: 768,
  //   xs: 576,
  // }
  breakPoints: {
    xl: 1536,
    lg: 1200,
    md: 900,
    sm: 600,
    xs: 0,
  },


}

export default mq

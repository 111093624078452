import React, { createContext, useState, useEffect } from "react"
import { number, object } from 'yup'

const CheckoutContext = createContext()

const CheckoutProvider = props => {
  const schema = object({
    current: number().integer().min(0).default(0),
    highest: number().integer().min(0).default(0),
  })

  const storage = typeof sessionStorage !== `undefined` ? sessionStorage : {}
  const init = storage.checkoutState && JSON.parse(storage.checkoutState)

  const [checkout, set] = useState(schema.isValidSync(init)
    ? schema.cast(init)
    : {}
  )

  const setCheckout = data => schema.validate(data).then(result => set(result))
  const resetCheckout = () => setCheckout()

  const store = {
    checkout,
    setCheckout,
    resetCheckout,
  }

  useEffect(() => storage.setItem('checkoutState', JSON.stringify(checkout)))

  return <CheckoutContext.Provider value={store} {...props} />
}

export { CheckoutProvider as default, CheckoutContext }

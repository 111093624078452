import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Box } from "@mui/material"
import styled from "styled-components"
import IconFacebook from '../../../static/svgs/inline/facebook.svg'
import IconInstagram from '../../../static/svgs/inline/instagram.svg'
import IconLinkedin from '../../../static/svgs/inline/linkedin.svg'
import IconTwitter from '../../../static/svgs/inline/twitter.svg'

const Inner = styled.div`
  .socialIcons {
    display: flex;

        a {
            margin-right: 1rem;
            &:last-child {
                margin-right: 0px;
            }
            svg {
                fill: white;
            }
            &:hover {
                svg {
                    fill: white;
                }
            }
        }

    }

`

const SocialAccounts = () => {

    const data = useStaticQuery(graphql`
    query SocialQuery {
        wp {
          companyDetails {
            companyDetails {
              socialAccounts {
                socialAccountDetails {
                  name
                  url
                  handle
                }
              }
            }
          }
        }
      }
  `)

    const iconUrl = account => {
        let url = ""

        switch (account.name.toLowerCase()) {
            case "twitter":
                url = account.url
                return url
            case "facebook":
                url = account.url
                return url
            case "linkedin":
                url = account.url
                return url
            case "instagram":
                url = account.url
                return url
            default:
                return url
        }
    }

    const iconImage = name => {
        switch (name.toLowerCase()) {
            case "twitter":
                return <IconTwitter height="20px" />
            case "facebook":
                return <IconFacebook height="20px" />
            case "linkedin":
                return <IconLinkedin height="20px" />
            case "instagram":
                return <IconInstagram height="20px" />
            default:
                return null;
        }
    }

    return (
        <Box sx={{ mt: 4 }}>
            <Inner>
                <div className="socialIcons">
                    {data.wp.companyDetails.companyDetails.socialAccounts.map(
                        (account, i) => {
                            return (
                                <a
                                    key={i}
                                    href={iconUrl(account.socialAccountDetails)}
                                    aria-label={`Head to Seetcombe's ${account.socialAccountDetails.name} account`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {iconImage(account.socialAccountDetails.name)}

                                </a>
                            )
                        }
                    )}
                </div>
            </Inner>
        </Box>
    )
}

export default SocialAccounts
//import mq from "../../mediaQueries"
import motion from "./menuMotion"
//import colors from "../../colors"

const menuStyles = {
  topMenu: {
    panelContainer: {},
    panel: {
      hasFeature: true,
      panelOpen: {},
      panelClosed: {},
      motion: motion,
    },
  },
  bottomMenu: {
    li: {
      active: {},
    },
    panelContainer: {},
    panel: {
      li: {},
      panelOpen: {},
      panelClosed: {},
      motion: motion,
    },
    menuOpen: {
      false: {},
      true: {},
    },
  },
}

export default menuStyles

import { css } from "styled-components"

const primaryWrapper = css`
  max-width: 1440px;
  margin: 0 auto;
  position:relative;
  width:100%;
  clear:both;
  padding-left: 1rem;
  padding-right: 1rem;
    @media ${props => props.theme.mq.sm} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    @media ${props => props.theme.mq.md} {
      padding-left: 3rem;
      padding-right: 3rem;
    }
`

const primaryContainer = css`
  margin-top: 1rem;
  margin-bottom: 1rem;
    @media ${props => props.theme.mq.sm} {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    @media ${props => props.theme.mq.md} {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
`

const wrappersContainers = {
  primaryWrapper,
  primaryContainer,
}

export default wrappersContainers
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { Grid, Container, Typography, Box } from "@mui/material"
import { FooterMenu } from "../footerMenu/footerMenu"
import Newsletter from "../newsletter/newsletter"
import { PartnerLogos } from "../partnerLogos/partnerLogos"


const Footer = props => {

  const data = useStaticQuery(graphql`
    query FooterQuery {
      wp {
        companyDetails {
          companyDetails {
            companyname
            companyemail
            companyphonenumber
          }
        }
      }
      allWpMenu(filter: {name: {eq: "Footer Menu"}}) {
        edges {
          node {
            menuItems {
              nodes {
                label
                parentId
                menu_icon {
                  menuIcon {
                    selectMenuIcon
                    showMenuIcon
                    showMenuText
                  }
                }
                subMenu: childItems {
                  nodes {
                    menu_icon {
                      menuIcon {
                        selectMenuIcon
                        showMenuIcon
                        showMenuText
                      }
                    }
                    url
                    path
                    label
                  }
                }
              }
            }
          }
        }
      }
      file(name: { eq: "feefoAward19" }) {
        childImageSharp {
          gatsbyImageData(
            width: 208
            height: 40
            quality: 50
          )
        }
      }
    }
  `)

  const deets = data.wp.companyDetails.companyDetails
  const parentMenus = data.allWpMenu.edges[0].node.menuItems.nodes.filter(i => !i.parentId);

  return (

    <>

      <Newsletter />
      <Container maxWidth="fluid" className="dontPrint" sx={{
        backgroundColor: 'secondary.main', px: { xs: 0 }
      }}>
        <Box sx={{ py: { xs: 5 } }}>
          <Container maxWidth="lg" sx={{ px: { xs: 5 } }}>
            <Typography sx={{ pb: { xs: 5, md: 5 } }} align="center" variant="h4" color="white">
              Outstanding holiday cottages with a personal touch
            </Typography>

            <FooterMenu
              footerMenu={parentMenus}
            />
          </Container>

        </Box>
      </Container >
      <Container maxWidth="fluid" className="dontPrint" sx={{ px: { xs: 0 } }}>
        <Container maxWidth="lg" sx={{ py: { xs: 4 }, px: { xs: 5 } }} >
          <Grid container spacing={3} sx={{ alignItems: 'center' }}>
            <Grid item xs={12} md={6}>
              <div className="foot">
                All rights reserved © {new Date().getFullYear()} {deets.companyname} - <Link to="/sitemap-index.xml" title="Sitemap">Sitemap</Link>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <PartnerLogos />
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  )
}

export default Footer

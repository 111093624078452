import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const Wrapper = styled.div`
height: 100%;
  .galleryItem{
    position: relative;
      span{
        display:none;
    }
  }
`

const Gallery = React.memo(({ content, icon }) => {

  const numRows = content.images.length

  return (
    <Wrapper className={`galleryWrapper gallery-` + numRows}>
      {content.images.map((image, i) => {
        return (
          <div className={`galleryItem item-` + i} key={i}>
            <GatsbyImage image={getImage(image.localFile.childImageSharp.gatsbyImageData)}
              key={i}
              alt={image.altText}
            />
            <span>{image.altText}</span>
          </div>
        )
      })}
    </Wrapper>
  )

})

export default Gallery
